import React, { useRef, useEffect } from 'react';
import './PaidServices.css';

function PaidServices({ onClose }) {
  const modalRef = useRef();

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="paid-services modal-content" ref={modalRef}>
        <button className="close-button" onClick={onClose}>✖</button>
        <h2>MatchUp Black</h2>
        <p className='paid-text'>
          Покажите свою заинтересованность в поиске второй половинки!
          <br />
          VIP Аккаунты получают больше внимания!
        </p>

        <div className="pricing-options">
          <div className="pricing-option">
            <div className="price-title">Неделя</div>
            <div className="price-value">₽ 399</div>
          </div>
          <div className="pricing-option">
            <div className="price-title">Месяц</div>
            <div className="price-value">₽ 899</div>
          </div>
        </div>

        <div className="benefits">
          <h3>Преимущества</h3>
          <ul>
            <li>В топе показов</li>
            <li>Эксклюзивное оформление страницы</li>
            <li>Суперлайки <span className="icon">⭐</span></li>
            <li>Отмена дизлайка <span className="icon">🔄</span></li>
            <li>Просмотр лайков <span className="icon">❤️</span></li>
            <li>Безлимитные лайки <span className="icon">✔️</span></li>
          </ul>
        </div>

        <div className="superlikes">
          <h3>Суперлайки</h3>
          <ul>
            <li>3⭐ - ₽ 199</li>
            <li>6⭐ - ₽ 397</li>
            <li>9⭐ - ₽ 555</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PaidServices;
