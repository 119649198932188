import React, { useState, useEffect } from 'react';
import matchup from './matchup.svg';
import './App.css';
import PaidServices from './PaidServices'; // Import the PaidServices component

function App() {
  const telegramBotLink = 'https://t.me/matchup_dating_bot';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);
  return (
    <div className="App">
      {isModalOpen && <PaidServices onClose={() => setIsModalOpen(false)} />}
      <div className="main-content">
        <div className={`logo-container ${isModalOpen ? '' : 'spinning'}`}>
          <img src={matchup} className="App-logo" alt="MatchUp logo" />
        </div>
        <div className="button-container">
          <a href={telegramBotLink} className="telegram-link">
            Перейти к Telegram-боту
          </a>
          <button
            onClick={() => setIsModalOpen(true)}
            className="paid-services-link"
          >
            Платные услуги
          </button>
        </div>
      </div>
      <div className="text-container">
        <p className="intro-text">
          Добро пожаловать в MatchUp Dating — первое и единственное Telegram-приложение для знакомств людям по всему миру! 🌍❤️
        </p>
        <h3>Настоящие люди, настоящие фото — никаких фейков! 🛡️</h3>
        <p>
          Забудьте о фейковых профилях и пустых аккаунтах! В MatchUp Dating каждый пользователь проходит строгую верификацию, поэтому вы можете быть уверены, что общаетесь только с реальными людьми. Вход в приложение возможен только после подтверждения личности, что гарантирует подлинность каждого профиля.
        </p>
        <h3>Удобство использования через Telegram 📱</h3>
        <p>
          Вам не нужно загружать отдельное приложение — MatchUp Dating работает прямо в Telegram. Это не только удобно, но и экономит ваше время и память на устройстве. Представьте, что весь процесс знакомства происходит в привычном мессенджере, который всегда под рукой. Всего один клик — и вы уже общаетесь с новыми людьми.
        </p>
        <h3>Максимальная простота и эффективность ⚡️</h3>
        <p>
          Мы понимаем, что время — ваш самый ценный ресурс. Именно поэтому MatchUp Dating создан с акцентом на простоту и эффективность. Никаких сложных интерфейсов и длинных анкет — только основная информация о вас, чтобы начать знакомиться без лишних заморочек. Мы убрали все ненужные функции, оставив только самое важное, чтобы вы могли сосредоточиться на общении и поиске своей половинки.
        </p>
        <h3>Прямое общение — никаких лишних чатов 💬</h3>
        <p>
          Здесь нет перегруженных чатов — при совпадении симпатий вы сразу получаете доступ к Telegram-профилям друг друга. Общайтесь напрямую, без лишних промежуточных звеньев, что делает процесс знакомства быстрым и удобным.
        </p>
        <h3>Безопасность и поддержка — всегда на вашей стороне 🛡️</h3>
        <p>
          В MatchUp Dating мы заботимся о безопасности и комфортном общении каждого пользователя. Если кто-то ведет себя неподобающе, вы всегда можете обратиться в нашу службу поддержки. Мы незамедлительно примем меры, чтобы защитить ваше право на безопасное и уважительное общение.
        </p>
        <h3>MatchUp Dating — ваш идеальный выбор для знакомств! 💖</h3>
        <p>
          Это ваш новый, простой и надежный способ найти интересных людей. Всё, что вам нужно, уже в Telegram. Не упускайте шанс испытать, как легко и эффективно могут проходить знакомства! Начните сейчас и убедитесь сами, что MatchUp Dating — это именно то, что вы искали.
        </p>
        <div className="centre">
          <h2 className="centre-text">Желаем вам успехов и незабываемых знакомств!</h2>
        </div>
      </div>
    </div>
  );
}

export default App;
